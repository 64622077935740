export const getURLImage = (nivel) => {
    switch (nivel) {
        case 1:
            return "https://defesacivil.poa.appdigital.tec.br/img/alertas/verde.jpg";
        case 2:
            return "https://defesacivil.poa.appdigital.tec.br/img/alertas/amarelo.jpg";
        case 3:
            return "https://defesacivil.poa.appdigital.tec.br/img/alertas/vermelho.jpg";
        case 4:
            return "https://defesacivil.poa.appdigital.tec.br/img/alertas/preto.jpg";
    }
};